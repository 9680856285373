<template>
  <v-sheet>
    <TextHeading class-name="ma-4" level=3>{{$t('company_products_label')}}</TextHeading>
    <product-box />
  </v-sheet>
</template>
<script>
import ProductBox from "@/components/ProductBox";
import TextHeading from "@/modules/TextHeading";
export default {
  components: {TextHeading, ProductBox}
}
</script>
